import React from 'react'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import api from 'services'
import { AppShellRouter, KeyRoute } from 'router'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'
import IndustryReportList from 'features/IndustryReport/IndustryReportList'
import IndustryReportCreate from 'features/IndustryReport/IndustryReportCreate'
import IndustryReportEdit from 'features/IndustryReport/IndustryReportEdit'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  let theme = createTheme(themeOptions, defaultLocale)
  theme = responsiveFontSizes(theme)

  return (
    <AppShellDataProvider value={appShellData}>
      <ApiProvider api={api}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <AppShellRouter currentKey={appShellData.currentKey}>
              <KeyRoute urlKey="prognosportalen-administration">
                <IndustryReportList />
              </KeyRoute>
              <KeyRoute urlKey="ny-publikation">
                <IndustryReportCreate />
              </KeyRoute>
              <KeyRoute urlKey="redigera-publikation">
                <IndustryReportEdit />
              </KeyRoute>
            </AppShellRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </ApiProvider>
    </AppShellDataProvider>
  )
}

export default App
