import React, { useState } from 'react'
import { Box, CircularProgress, Snackbar, Stack } from '@mui/material'
import Header from 'components/Header'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Content } from 'types/Content.type'
import { useForm } from 'react-hook-form'
import { useLazyGetIndustryReportQuery } from 'services/kompetens'
import { useRouteMatch } from 'react-router-dom'
import { isDate, parseISO } from 'date-fns'
import { useUpdateIndustryReportMutation } from 'services/kompetens/kompetens'
import { OccupationGroup } from 'types/OccupationGroup'
import { Region, Status, Type } from 'types/IndustryReport'

import Preview from './Preview'
import Details from './Details'
import Form from './Form'

export interface MatchParams {
  id: string
}

export interface FormData {
  id?: string
  title: string
  description: string
  publisher: string
  publicationDate: Date | null
  internalComment?: string | undefined
  occupationGroups: OccupationGroup[]
  allRegions: boolean
  regions: Region[]
  status?: Status
}

const IndustryReportEdit = () => {
  const {
    redigeraPublikation: {
      heading,
      formTitleLabel,
      formDescriptionLabel,
      formPublisherLabel,
      formPublicationDateLabel,
      formOccupationGroupLabel,
      formAllRegionsErrorMessage,
      snackbarPublicationPublished,
      snackbarError,
      snackbarPublicationSaved,
    },
  } = useEpiContent<Content>()
  const customDictionary = useEpiCustomDictionary(['redigeraPublikation'])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [updateReport] = useUpdateIndustryReportMutation()
  const [isLoading, setIsLoading] = useState(true)
  const match = useRouteMatch<MatchParams>(
    '/medarbetare/prognosportalen-administration/redigera-publikation/:id'
  )
  const industryReportId = match?.params?.id ?? ''
  const [trigger, result] = useLazyGetIndustryReportQuery()

  const schema = yup
    .object({
      title: yup.string().required(
        customDictionary('formRequiredFieldLabel', {
          formInputLabel: formTitleLabel,
        })
      ),
      description: yup.string().required(
        customDictionary('formRequiredFieldLabel', {
          formInputLabel: formDescriptionLabel,
        })
      ),
      publisher: yup.string().required(
        customDictionary('formRequiredFieldLabel', {
          formInputLabel: formPublisherLabel,
        })
      ),
      publicationDate: yup
        .date()
        .nullable()
        .defined()
        .test(
          'isDate',
          customDictionary('formRequiredFieldLabel', {
            formInputLabel: formPublicationDateLabel,
          }),
          (value) => {
            return isDate(value)
          }
        ),
      occupationGroups: yup
        .array()
        .min(
          1,
          customDictionary('formOccupationGroupRequiredFieldLabel', {
            occupationGroup: formOccupationGroupLabel.toLowerCase(),
            amount: 'en',
          })
        )
        .of(
          yup.object({
            id: yup.string().defined(),
            occupationGroupName: yup.string().defined(),
          })
        )
        .required(),
      allRegions: yup.boolean().defined(),
      regions: yup
        .array()
        .of(
          yup.object({
            id: yup.string().defined(),
            name: yup.string().defined(),
          })
        )
        .when('allRegions', {
          is: false,
          then: (schema) => schema.min(1, formAllRegionsErrorMessage),
          otherwise: (schema) => schema,
        })
        .required(),
      internalComment: yup.string(),
    })
    .required()

  const getDefaultValues = async () => {
    const result = await trigger(industryReportId)
    setIsLoading(false)

    const publicationDate =
      result.data?.publicationDate === null
        ? null
        : parseISO(result.data?.publicationDate as string)

    return {
      title: result.data?.title ?? '',
      description: result.data?.description ?? '',
      publisher: result.data?.publisher ?? '',
      publicationDate: publicationDate,
      status: result.data?.status,
      internalComment: result.data?.internalComment ?? '',
      occupationGroups: result.data?.occupationGroups ?? [],
      allRegions: result.data?.allRegions ?? false,
      regions: result.data?.regions ?? [],
    }
  }

  const form = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: async () => getDefaultValues(),
  })
  const [title, description, publisher, publicationDate] = form.watch([
    'title',
    'description',
    'publisher',
    'publicationDate',
  ])

  const onSubmit = async (
    data: FormData,
    event?: React.SyntheticEvent,
    message = snackbarPublicationPublished
  ) => {
    const occupationGroups = data.occupationGroups?.map((el) => ({
      id: el.id,
      occupationGroupName: el.occupationGroupName,
    })) as OccupationGroup[]

    try {
      await updateReport({
        ...data,
        id: industryReportId,
        occupationGroups,
        status: 'Published',
      })
      setSnackbarMessage(message)
    } catch (error) {
      setSnackbarMessage(snackbarError)
    }
  }

  const handleSave = async (
    status?: Status,
    message = snackbarPublicationSaved
  ) => {
    const formValues = form.getValues()
    const occupationGroups = form.getValues('occupationGroups')?.map((el) => ({
      id: el.id,
      occupationGroupName: el.occupationGroupName,
    })) as OccupationGroup[]

    // If the status is published we need to revalidate the fields
    if (result.data?.status === 'Published' && status !== 'Archived') {
      const valid = await form.trigger()

      if (valid) return onSubmit(formValues, undefined, message)

      return
    }

    // We clear the validation errors if the status is 'Archived' or 'Draft'
    form.clearErrors()

    try {
      await updateReport({
        ...formValues,
        id: industryReportId,
        status: status ? status : result.data?.status,
        occupationGroups,
      }).unwrap()
      setSnackbarMessage(message)
    } catch (error) {
      setSnackbarMessage(snackbarError)
    }
  }

  if (isLoading) return <CircularProgress />

  return (
    <Box>
      <Header heading={heading} />
      <Stack
        direction={{ md: 'column', lg: 'row' }}
        spacing={{ md: 4, lg: 4 }}
        useFlexGap
        flexWrap="wrap"
      >
        <Details
          id={industryReportId}
          status={result?.data?.status}
          createdAt={result?.data?.createdAt ?? ''}
          createdBy={result?.data?.createdBy ?? ''}
          latestRevision={result?.data?.latestRevision ?? null}
          onSubmit={onSubmit}
          handleSave={handleSave}
          formHandleSubmit={form.handleSubmit}
        />

        <Box order={{ md: 2, lg: 1 }} sx={{ flexBasis: { lg: '700px' } }}>
          <Preview
            id={industryReportId}
            title={title}
            description={description}
            publisher={publisher}
            publicationDate={publicationDate}
            fileName={result?.data?.fileName ?? ''}
            type={result?.data?.type as Type}
          />
          <Form onSubmit={onSubmit} handleSave={handleSave} form={form} />
        </Box>
      </Stack>
      <Snackbar
        open={snackbarMessage !== ''}
        onClose={() => setSnackbarMessage('')}
        autoHideDuration={5000}
        message={snackbarMessage}
        data-testid="snackbar"
      />
    </Box>
  )
}
export default IndustryReportEdit
