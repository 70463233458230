import {
  Box,
  Typography,
  Card,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Snackbar,
  Portal,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import React, { useState } from 'react'
import { IndustryReport } from 'types'
import { sv } from 'date-fns/locale'
import { useGetUserProfilesQuery } from 'services/userProfile/userProfile'
import { useDeleteIndustryReportMutation } from 'services/kompetens'
import { useHistory } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import { Status } from 'types/IndustryReport'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { Content } from 'types/Content.type'
import { publicationStatusMapper, publicationVisibilityMapper } from 'utils'
import { UseFormHandleSubmit } from 'react-hook-form'

import { FormData } from '../IndustryReportEdit'

interface DetailsProps
  extends Pick<
    IndustryReport,
    'id' | 'status' | 'createdAt' | 'createdBy' | 'latestRevision'
  > {
  onSubmit: (data: FormData) => Promise<void>
  handleSave: (status?: Status, message?: string) => Promise<void>
  formHandleSubmit: UseFormHandleSubmit<FormData, undefined>
}

const Details = ({
  id,
  status,
  createdAt,
  createdBy,
  latestRevision,
  onSubmit,
  handleSave,
  formHandleSubmit,
}: DetailsProps) => {
  const history = useHistory()
  const {
    redigeraPublikation: {
      snackbarPublicationDeleted,
      detailsHeading,
      detailsDisplayedFor,
      detailsLastEdited,
      detailsCreated,
      detailsPublishButtonLabel,
      snackbarPublicationArchived,
      detailsDeleteButtonLabel,
      detailsDeletePublicationDialogTitle,
      detailsDeletePublicationDialogBody,
      detailsDeletePublicationDialogCancelButtonLabel,
      detailsDeletePublicationDialogDeleteButtonLabel,
      published,
      archived,
      draft,
      all,
      administrators,
      detailsArchiveButtonLabel,
    },
  } = useEpiContent<Content>()
  const customDictionary = useEpiCustomDictionary(['redigeraPublikation'])
  const [deleteReport] = useDeleteIndustryReportMutation()
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const { data, isLoading } = useGetUserProfilesQuery(
    [createdBy, latestRevision?.updatedBy ?? ''],
    {
      skip:
        createdBy === '' ||
        createdBy === '00000000-0000-0000-0000-000000000000' ||
        !latestRevision,
    }
  )

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteIndustryReport = async () => {
    if (id) {
      try {
        await deleteReport(id).unwrap()
        setSnackbarMessage(snackbarPublicationDeleted)
        window.setTimeout(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          () => history.push('/medarbetare/prognosportalen-administration/'),
          2000
        )
      } catch (err) {
        setSnackbarMessage(
          customDictionary('snackbarDeleteError', {
            publicationType: 'publikationen',
          })
        )
      }
    }
  }

  if (isLoading) return <CircularProgress />

  return (
    <Box
      data-testid="details"
      sx={{
        flexBasis: { lg: '360px' },
        paddingTop: { lg: 5.5 },
        position: { lg: 'sticky' },
        alignSelf: { lg: 'flex-start' },
        top: { lg: 0 },
        overflowY: { lg: 'auto' },
      }}
      order={{ md: 1, lg: 2 }}
    >
      <Card sx={{ padding: 3 }}>
        <Typography variant="h5">{detailsHeading}</Typography>
        <List>
          <ListItem
            disableGutters
            sx={{
              borderBottom: 1,
              borderColor: (theme) => theme.palette.neutral?.divider,
              padding: '0.4em 0em 0.6em 0em',
            }}
            secondaryAction={
              <Typography variant="body1" fontWeight={'Bold'}>
                {publicationStatusMapper(status, published, draft, archived)}
              </Typography>
            }
          >
            <ListItemText primary="Status" />
          </ListItem>

          <ListItem
            disableGutters
            sx={{
              borderBottom: 1,
              borderColor: (theme) => theme.palette.neutral?.divider,
              padding: '0.4em 0em 0.6em 0em',
            }}
            secondaryAction={
              <Typography variant="body1" fontWeight={'Bold'}>
                {publicationVisibilityMapper(status, all, administrators)}
              </Typography>
            }
          >
            <ListItemText primary={detailsDisplayedFor} />
          </ListItem>

          {latestRevision && (
            <ListItem
              disableGutters
              sx={{
                borderBottom: 1,
                borderColor: (theme) => theme.palette.neutral?.divider,
                padding: '0.4em 0em 0.6em 0em',
              }}
            >
              <ListItemText
                primary={detailsLastEdited}
                secondary={`${format(
                  parseISO(latestRevision.updatedAt),
                  'dd MMMM yyyy (HH.mm)',
                  {
                    locale: sv,
                  }
                )} av ${data?.[1].firstName} ${data?.[1].lastName}`}
              />
            </ListItem>
          )}
          {createdBy !== '' &&
            createdBy !== '00000000-0000-0000-0000-000000000000' && (
              <ListItem disableGutters sx={{ padding: '0.4em 0em 0.6em 0em' }}>
                <ListItemText
                  primary={detailsCreated}
                  secondary={`${format(
                    parseISO(createdAt),
                    'dd MMMM yyyy (HH.mm)',
                    {
                      locale: sv,
                    }
                  )} av ${data?.[0].firstName} ${data?.[0].lastName}`}
                />
              </ListItem>
            )}
        </List>
        <Stack direction={'row'} spacing={1} sx={{ marginTop: 2 }}>
          {status === 'Published' ? (
            <Button
              size="small"
              onClick={() =>
                void handleSave('Archived', snackbarPublicationArchived)
              }
              data-testid="archive-button"
            >
              {detailsArchiveButtonLabel}
            </Button>
          ) : (
            <Button
              size="small"
              onClick={(event) => {
                void formHandleSubmit(onSubmit)(event)
              }}
              data-testid="publish-button"
            >
              {detailsPublishButtonLabel}
            </Button>
          )}

          <Button
            variant="text"
            size="small"
            onClick={handleClickOpen}
            startIcon={<Delete />}
            data-testid="delete-publication-button"
          >
            {detailsDeleteButtonLabel}
          </Button>
        </Stack>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {detailsDeletePublicationDialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {detailsDeletePublicationDialogBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            {detailsDeletePublicationDialogCancelButtonLabel}
          </Button>
          <Button
            variant="text"
            onClick={() => void handleDeleteIndustryReport()}
            data-testid="confirm-delete-publication-button"
          >
            {detailsDeletePublicationDialogDeleteButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <Portal>
        <Snackbar
          open={snackbarMessage !== ''}
          autoHideDuration={6000}
          message={snackbarMessage}
        />
      </Portal>
    </Box>
  )
}

export default Details
