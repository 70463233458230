import { UserProfile } from 'types/UserProfile.type'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import api from '../api'

import fetchUserProfile from './utils/fetchUserProfile'

const userProfileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserProfiles: build.query<UserProfile[], string[]>({
      queryFn: async (ids: string[]) => {
        // Makes it possible to make multiple user requests
        const firstUserResult = await fetchUserProfile(ids[0])
        if (firstUserResult.error)
          return { error: firstUserResult.error as FetchBaseQueryError }

        const user = firstUserResult.data as UserProfile

        const secondUserResult = await fetchUserProfile(ids[1])
        const resultData = secondUserResult.data as UserProfile

        const combo = [{ ...user }, { ...resultData }]

        return secondUserResult.data
          ? { data: combo as UserProfile[] }
          : { error: secondUserResult.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const { useGetUserProfilesQuery } = userProfileApi
