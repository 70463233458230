import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react/'
import { getIdToken } from '@trr/app-shell-data'
import queryString from 'query-string'
import { getConfig } from 'utils'

const { MEDARBETARE_API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: MEDARBETARE_API_URL,
    paramsSerializer: (params: Record<string, string>) =>
      queryString.stringify(params),
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken()}`)
      headers.set('Accept-Language', 'sv')

      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: () => ({}),
  tagTypes: ['IndustryReport', 'OccupationGroup', 'Region', 'County'],
})

export default api
