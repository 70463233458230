import { ArrowDropUp, ArrowDropDown, Close } from '@mui/icons-material'
import {
  Chip,
  Box,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  Avatar,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { CustomPopper } from 'components/CustomPopper/CustomPopper'
import React, { useEffect, useRef, useState } from 'react'
import { Content } from 'types/Content.type'

export type Data = {
  id: string
  label: string
}

export interface FilterType {
  id: string
  name: string
  label: string
  filters: string[]
  data?: Data[]
}

const Filter = ({
  filter,
  handleSetFilters,
  handleResetFilters,
}: {
  filter: FilterType
  handleSetFilters: (filterId: string, value: string) => void
  handleResetFilters: (filterId: string) => void
}) => {
  const {
    prognosportalenAdministration: { clearLabel, noFilters },
  } = useEpiContent<Content>()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const ResetButton = () => (
    <Button
      variant="text"
      onClick={() => handleResetFilters(filter.id)}
      disabled={filter.filters.length === 0}
      sx={{ alignSelf: 'flex-end' }}
      data-testid={`clear-${filter.name}-filter`}
    >
      {clearLabel}
    </Button>
  )

  const chipRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (open) {
      const chipBottom = chipRef.current?.offsetTop as number
      const popperHeight = 700
      const viewportBottom = window.scrollY + window.innerHeight
      const shouldScrollIntoView = chipBottom + popperHeight > viewportBottom
      shouldScrollIntoView &&
        chipRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [open])

  const SelectCountIndicator = (
    <Avatar
      sx={{
        bgcolor: (theme) => theme.palette.secondary.main,
        color: '#fff !important',
      }}
    >
      {filter.filters.length}
    </Avatar>
  )

  return (
    <>
      <Chip
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '-7px',
            }}
            component={'span'}
          >
            {filter.label} {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </Box>
        }
        onClick={handleOpen}
        ref={chipRef}
        sx={{ zIndex: open ? 2 : 'unset' }}
        avatar={filter.filters.length > 0 ? SelectCountIndicator : undefined}
        aria-describedby="filter-item-chip"
        data-testid={`filter-chip-${filter.name}`}
        variant={open || filter.filters.length > 0 ? 'filled' : 'outlined'}
      />
      <CustomPopper
        label={filter.label}
        headerIcon={<Close />}
        footer={<ResetButton />}
        open={open}
        anchor={chipRef.current as HTMLElement}
        onClickHeaderIcon={handleClose}
        onClickAway={handleClose}
      >
        <List sx={{ paddingBottom: '.5rem' }} disablePadding>
          {filter.data?.length === 0 && (
            <Typography variant="subtitle2">{noFilters}</Typography>
          )}
          {filter.data?.map((option) => (
            <ListItem
              key={option.id}
              disablePadding
              divider={false}
              data-testid="checkbox-list-item"
            >
              <ListItemButton
                role="button"
                sx={{ paddingTop: 0, paddingBottom: 0, left: -5 }}
                onClick={() => handleSetFilters(filter.id, option.id)}
                dense
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    edge="start"
                    checked={
                      filter.filters.some((filter) => filter === option.id) ??
                      false
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={
                      {
                        'aria-labelledby': option.label,
                        'data-testid': `filter-checkbox-${option.id}`,
                      } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                </ListItemIcon>
                <ListItemText id={option.id} primary={option.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CustomPopper>
    </>
  )
}

export default Filter
