import { Type } from 'types/IndustryReport'

const publicationTypeMapper = (type: Type) => {
  switch (type) {
    case 'Document':
      return 'Rapport'

    default:
      return 'Publikation'
  }
}

export default publicationTypeMapper
