import { Stack } from '@mui/material'
import React from 'react'

import Filter from './Filter'
import { FilterType } from './Filter/Filter'

const Filters = ({
  filters,
  handleSetFilters,
  handleResetFilters,
}: {
  filters: FilterType[]
  handleSetFilters: (filterId: string, value: string) => void
  handleResetFilters: (filterId: string) => void
}) => {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      sx={{ marginTop: 2, paddingBottom: 4 }}
      data-testid="filters"
    >
      {filters.map((el) => (
        <Filter
          key={el.id}
          filter={el}
          handleSetFilters={handleSetFilters}
          handleResetFilters={handleResetFilters}
        />
      ))}
    </Stack>
  )
}

export default Filters
